import React, { FC } from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import htmlElemen_1 from "../../images/monitoringScreens/html-element-advanced_1.jpg";
import htmlElemen_2 from "../../images/monitoringScreens/html-element-advanced_2.jpg";
import htmlElemen_3 from "../../images/monitoringScreens/html-element-advanced_3.jpg";
import htmlElemen_4 from "../../images/monitoringScreens/html-element-advanced_4.jpg";
import htmlElemen_5 from "../../images/monitoringScreens/html-element-advanced_5.jpg";
import htmlElemen_6 from "../../images/monitoringScreens/html-element-advanced_6.jpg";
import htmlElemen_7 from "../../images/monitoringScreens/html-element-advanced_7.jpg";
import htmlElemen_8 from "../../images/monitoringScreens/html-element-advanced_8.jpg";

import useCalculateHeightHook from "../../hooks/useCalculateHeightHook";
import "./styles.scss";

const HTMLElementMonitoring: FC = () => {
  const [size, videoLength] = useCalculateHeightHook();

  return (
    <div className="content-wrapper">
      <PageHero
        title="HTML element advanced monitoring"
        subtitle="Monitor specific HTML elements or the content of any field for changes."
      />
      <Layout>
        <Section>
          <div className="col-lg-12 html-element-monitoring">
            <div className="go-back">
              <a className="learn_btn_two" href="/how-to-use-hexowatch">
                <svg
                  width="40px"
                  height="20px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="long-arrow-alt-left"
                  className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                  ></path>
                </svg>
                Back to how to use Hexowatch
              </a>
            </div>

            {size ? (
              <video
                controls
                poster={htmlElemen_1}
                preload="none"
                ref={videoLength}
                style={{ height: size ? size : "auto" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/HTML_element_monitor_-_Advanced_Walkthrough_1598173141.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                controls
                poster={htmlElemen_1}
                preload="none"
                ref={videoLength}
                style={{ visibility: "hidden" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/HTML_element_monitor_-_Advanced_Walkthrough_1598173141.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <h2>HTML element monitor - Advanced Walkthrough</h2>
            <br />
            <div className="img-block">
              <img
                src={htmlElemen_1}
                alt="Select HTML element monitoring tool"
              />
            </div>
            <p className="mb">
              The HTML element monitor works like a web browser and lets you
              monitor specific elements for changes. This is ideal for
              monitoring and tracking prices or values on third party pages.
            </p>

            <div className="img-block">
              <img src={htmlElemen_2} alt="Preview desired URL" />
            </div>
            <p className="mb">
              In this video, we will cover some of the advanced features of this
              monitor to handle more complex situations. To get started enter
              the URL you want to check and click preview.
            </p>

            <div className="img-block">
              <img
                src={htmlElemen_3}
                alt="Select html element and right clicking to call up the actions button"
              />
            </div>
            <p className="mb">
              Once the page has loaded, you can interact with the page, by
              clicking any element to select it and right-clicking to call up
              the actions button.
            </p>

            <div className="img-block">
              <img
                src={htmlElemen_4}
                alt="Perform actions on a page like as if you were using a web browser"
              />
            </div>
            <p className="mb">
              You can then perform actions on a page as if you were using a web
              browser. This is useful for monitoring password-protected pages,
              navigating to different pages, filling out forms, or closing any
              visual pop-ups on the page.
            </p>

            <div className="img-block">
              <img
                src={htmlElemen_5}
                alt="The actions area lets you click any element on page"
              />
            </div>
            <p className="mb">
              The actions area lets you click any element on the page, type
              inside form elements, add a delay to ensure all parts of a page
              are loaded in time, block any element of the page, refresh the
              page or set cookie values manually.
            </p>

            <div className="img-block">
              <img
                src={htmlElemen_6}
                alt="In this example we clicked the pricing page link"
              />
            </div>
            <p className="mb">
              In this example we clicked the pricing page link, and we can now
              select the price we want to monitor. The possibilities are
              endless, as you can program a wide range of interactions on the
              page before capturing the value or area you want to monitor.
            </p>

            <div className="img-block">
              <img
                src={htmlElemen_7}
                alt="You can choose the frequency of the checks"
              />
            </div>
            <p className="mb">
              Next, you can choose the frequency of the checks, your preferred
              notification channels, as well as set custom start and end dates.
            </p>

            <div className="img-block">
              <img
                src={htmlElemen_8}
                alt="Click start monitoring to get started"
              />
            </div>
            <p className="mb">
              Then click start monitoring to get started. Hexowatch will keep an
              eye on the page and will notify you when this specific element of
              the page changes.
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default HTMLElementMonitoring;
