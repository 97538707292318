import React, {Component} from 'react';
import {Translate} from '../../components/translations';
import TriangleTwo from '../../images/triangle_two.png';
import TriangleThree from '../../images/triangle_three.png';
import {PageHeroProps} from '../../types';
import './styles.scss';
import Counter from '../header/counter';

export class PageHero extends Component<PageHeroProps> {
  render() {
    const {title, subtitle, subtitle1, children, noPaddingBottom, countdown, pricingPage} = this.props;
    // const cookiePadding = getCookiePolicy() === "registered" ? 'no-cookie' : 'with-cookie'

    return (
      <section id="seo_home_area" className={`seo_home_area ${noPaddingBottom ? 'noPaddingBottom' : ''} ${pricingPage ? 'pricing-page' : ''}`}>
        <div className="container">
          <div className="breadcrumb_content text-center">
            {title && (
              <h1 className="page-hero-title">{typeof title === 'string' ? <Translate name={title} /> : title}</h1>
            )}
            {subtitle && (
              <p className="page-hero-subTitle">
                {typeof subtitle === 'string' ? <Translate name={subtitle} /> : subtitle}
              </p>
            )}
            {subtitle1 && (
              <p className="page-hero-subTitle">
                {typeof subtitle1 === 'string' ? <Translate name={subtitle1} /> : subtitle1}
              </p>
            )}
            <div className="d-flex">
              {countdown && <Counter date="Jun 27 2023 07:00:00 GMT" className='m-auto' title='ENDS IN...' />}
            </div>

            {children}
          </div>
        </div>
      </section>
    );
  }
}
