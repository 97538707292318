import React, { useRef, useEffect, useState } from "react";

const calculateHeightHook = () => {
  const ref = useRef<any>(null);
  const [size, setHeight] = useState<any>(null);

  useEffect(() => {
    const updateSize = () => {
      if (ref && ref.current) {
        const heightSize = 0.75 * ref.current.offsetWidth
        setHeight(heightSize)
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return [size, ref]
}

export default calculateHeightHook;